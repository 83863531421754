import React from 'react';
import { Link } from 'gatsby';
import styles from './fixedlink.module.scss';
import { BsPlus } from "react-icons/bs"

const FixedLink = () => {
    return (
        <Link to="/anadir-inspiranos" className={styles.fixedLink}>
            <BsPlus />
            Añadir <br/>foto
        </Link>
    );
}

export default FixedLink;
